.skills-container {
    max-width: 800px;
    width: 90%;
    margin: 0 auto 70px auto;
}

h3 {
    margin-bottom: 0px;
}

.skills-header {
    display: flex;
    align-items: center;
    margin-top: 25px;
    margin-bottom: 20px;
    cursor: pointer;
    width: 100%;
    justify-content: center;
    transition: .4s;
}

.skills-icon, .skills-arrow {
    font-size: 25px;
    transition: .4s;
}

.skills-icon {
    margin-right: 10px;
}

.skills-arrow {
    margin-left: auto;
    transition: .4s;
}

.skills-title {
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;
}

.skills-name {
    font-size: 20px;
}

.skills-number {
    font-weight: 200;
    color: #B8B8B8;
}

.skills-open .skills-arrow {
    transform: rotate(180deg);
}

.skills-data {
    width: 85%;
    margin: 0 auto;
    max-height: 1000px;
    transition: max-height 1s ease-in-out;
    overflow: hidden;
}

.skills-data[aria-expanded="false"] {
    max-height: 0px;
    transition: max-height 0.5s cubic-bezier(0, 1, 0, 1)
}

.skills-content {
    margin-bottom: 10px;
}

.skills-header:hover {
    letter-spacing: 2px;
}

@media screen and (max-width: 1200px) {
    h3 {
        font-size: 25px;
    }

    .skills-icon, .skills-arrow {
        font-size: 20px;
    }

    .skills-name {
        font-size: 18px;
    }
}

@media screen and (max-width: 768px) {
    .skills-container {
        width: 100%;
        margin-bottom: 40px;
    }

    .skills-header {
        margin-top: 15px;
        text-align: left;
    }

    .skills-title {
        flex-direction: column;
    }

    h3 {
        font-size: 20px;
    }

    .skills-icon, .skills-arrow {
        font-size: 18px;
    }

    .skills-name {
        font-size: 14px;
    }
}