footer {
    padding: 30px 0 30px 0;
    font-weight: 400;
    letter-spacing: 0.5px;
    line-height: 24px;
  }

.social-footer {
    display: flex;
    justify-content: center;
    margin: 0;
}

.footer p {
    font-weight: 400;
    font-size: 14px;
    color: #B8B8B8;
    letter-spacing: 0.5px;
    margin: 20px 0 0 0 !important;
    text-align: center;
  }