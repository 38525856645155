.experience-container {
    max-width: 1000px;
    margin: 0 auto;
}

.experience-tabs {
    display: flex;
    justify-content: space-evenly;
    margin-bottom: 25px;
}

.experience-button {
    font-size: 22px;
    cursor: pointer;
    transition: .4s;
    align-items: center;
    display: flex;
    border: 1px solid white;
    padding: 10px 20px;
    border-radius: 50px;
}

.experience-icon {
    font-size: 30px;
}

.experience-button .experience-name {
    max-width: 0px;
    overflow: hidden;
    transition: max-width 1.5s cubic-bezier(0, 1, 0, 1), margin-left 1s ease-in-out;
}

.experience-button:hover .experience-name {
    max-width: 1000px;
    margin-left: 10px;
    overflow: hidden;
    transition: max-width 1.5s ease-in-out;
    
}

.experience-data {
    display: grid;
    grid-template-columns: 1fr max-content 1fr;
    column-gap: 1.5rem;
}

.right {
    text-align: right;
}

.left {
    text-align: left;
}

.right, .left {
    margin-bottom: 20px;
}

.experience-title {
    font-size: 22px;
    margin-bottom: 5px;
}

.experience-subtitle {
    display: inline-block;
    font-size: 18px;
    margin-bottom: 5px;
}

.experience-calendar {
    font-size: 18px;
    color: #B8B8B8;
    display: flex;
    align-items: center;
}

.right .experience-calendar {
    justify-content: flex-end;
}

.experience-calendar svg {
    margin-right: 10px;
}

.experience-rounder {
    display: inline-block;
    width: 13px;
    height: 13px;
    background-color: white;
    border-radius: 50%;
}

.experience-line {
    display: block;
    width: 1px;
    height: 100%;
    background-color: white;
    transform: translate(6px, -7px);
}

.experience [data-content] {
    display: none;
}

.experience-active[data-content] {
    display: block;
}

.experience-active .experience-name {
    max-width: 1000px!important;
    margin-left: 10px;
}

@media (max-width: 1200px) {
    .experience-button {
        font-size: 20px;
    }

    .experience-icon {
        font-size: 25px;
    }

    .experience-title {
        font-size: 20px;
    }

    .experience-subtitle {
        font-size: 16px;
    }

    .experience-calendar {
        font-size: 16px;
    }

    .experience-tabs {
        margin-top: 30px;
        margin-bottom: 25px;
    }

    .experience-container {
        width: 90%;
    }
}

@media (max-width: 768px) {

    .experience-container {
        width: 100%;
    }

    .experience-icon {
        font-size: 18px;
    }

    .experience-button {
        font-size: 16px;
    }

    .experience-title {
        font-size: 14px;
    }

    .experience-subtitle {
        font-size: 12px;
    }

    .experience-calendar {
        font-size: 12px;
    }

    .experience-data {
        column-gap: 1rem;
    }

    .experience-calendar svg {
        display: none;
    }
}